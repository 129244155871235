import React, { useState, useRef, useEffect, useCallback } from 'react'
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Grid,
  Box,
  TextField,
  Divider,
  Stack,
  InputAdornment,
  Alert,
  CircularProgress,
  Backdrop,
} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { submitTechnicalApproval, uploadPdf } from '../../../../../redux/actions/offer'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import ModalTableHead from './../components/table_head'
import 'react-datepicker/dist/react-datepicker.css'
import { APP_FONT } from '../../../../../constants/app_font'
import { Offer } from '../../../../../models/offer_new'
import FilePreview from './../components/file_preview'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as DownloadIcon } from '../../../../../assets/images/download.svg'
import { ReactComponent as PdfDownloadIcon } from '../../../../../assets/images/pdf_download.svg'
import { formatPrice, sanitizeNumber } from '../../../../../components/helper/helper'
import { ReactComponent as Close } from '../../../../../assets/images/close.svg'
import { RecServiceBlock } from './../inspection_upload/RecServiceBlock'
import { Service } from './../inspection_upload/inspection_upload'
import { RecommendedService, RecommendedServicePart } from './../service.model'
import { ReactComponent as AddIcon } from '../../../../../assets/images/add_icon.svg'
import { ReactComponent as PlusIcon } from '../../../../../assets/images/plus_white.svg'
import { validateRecommendedServiceFields } from './../service.validation'
import { useUploadRecServiceImagesMutation } from '../../../../../redux/api/serviceApi/serviceApi'
import { useGetParentService } from '../../../../../hooks/useGetParentService'
import Decimal from 'decimal.js'
import { calculateSubTotalPrice } from '../calculation'

const MAX_FILE_SIZE = 25 * 1024 * 1024

interface DiagnosticUploadModalProps {
  setOpen: (open: boolean) => void
  open: boolean
  onClose: () => void
  onSubmit: () => void
  offer: Offer
  services: Service[]
}

interface ServicePrices {
  [serviceId: string]: string
}

const DiagnosticUploadModal: React.FC<DiagnosticUploadModalProps> = ({
  setOpen,
  open,
  onClose,
  onSubmit,
  offer,
  services,
}) => {
  const dispatch = useDispatch()
  const [file, setFile] = useState<File | null>(null)
  const [uploadStatus, setUploadStatus] = useState<'IDLE' | 'UPLOADING' | 'SUCCESS' | 'ERROR'>(
    'IDLE'
  )
  const navigate = useNavigate()
  const [recServices, setRecServices] = useState<RecommendedService[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [servicePrices, setServicePrices] = useState<ServicePrices>({})
  const [fee, setFee] = useState<string>()
  const [totalFee, setTotalFee] = useState<string>()
  const [shopSupplyFees, setShopSupplyFees] = useState<string>('')
  const [tax, setTax] = useState<string>('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [diagnosticFeedbackFile, setDiagnosticFeedbackFile] = useState<any | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showAdditionalServices, setShowAdditionalServices] = useState(false)
  const [diagnosticResult, setDiagnosticResult] = useState<string | null>(null)
  const [subtotalRequestedServices, setSubtotalRequestedServices] = useState<string>('')

  const handleUploadClick = () => {
    fileInputRef.current?.click()
  }
  const handleServicePartChange = (
    serviceIndex: number,
    partIndex: number,
    field: keyof RecommendedServicePart,
    value: string
  ) => {
    setRecServices((currentServices) =>
      currentServices.map((service, idx) => {
        if (idx === serviceIndex) {
          return {
            ...service,
            parts: service.parts.map((part, pIndex) => {
              if (pIndex === partIndex) {
                let newValue: any = value

                if (field === 'quantity' || field === 'number') {
                  const inputValue = newValue.replace(/[^0-9]/g, '')
                  if (inputValue === '') {
                    newValue = ''
                  } else {
                    const numberValue = parseInt(inputValue, 10)
                    newValue = numberValue
                  }
                } else if (field === 'price_per_unit') {
                  const input = value.replace(/[^0-9]/g, '')

                  const number = parseInt(input, 10) / 100

                  newValue = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                    .format(number)
                    .slice(1)
                }

                return { ...part, [field]: newValue }
              }
              return part
            }),
          }
        }
        return service
      })
    )
  }

  const handleServicePriceChange = (
    serviceId: string,
    serviceType: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setServicePrices((prevPrices) => ({
      ...prevPrices,
      [`${serviceId}_${serviceType}`]: formattedNumber,
    }))
  }

  const { isSuccess, getParentService } = useGetParentService()

  useEffect(() => {
    if (isSuccess) {
      const initialRecommendedServices = offer?.services
        .filter((service) => !service.is_approved)
        .map((service) => {
          const serviceId = service.service.id
          const parentService = getParentService(serviceId)
          const matchingService = services.find(
            (s) => s.id === service.service.parent.id || s.id === service.service.id
          )

          if (matchingService) {
            const formattedNumber = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
              .format(service.price)
              .slice(1)

            setServicePrices((prevPrices) => ({
              ...prevPrices,
              [`${serviceId}_${service.service.id}`]: formattedNumber,
            }))

            return {
              id: parentService?.id,
              name: parentService?.name,
              type: parentService?.child?.id,
              types: parentService?.children,
              price: service.price,
              serviceType: service.service.type,
              parts:
                service.parts.map((part) => ({
                  name: part.name,
                  quantity: part.quantity,
                  price_per_unit: part.price_per_unit,
                  number: part.number,
                })) || [],
              labor_hours: service.labor_hours,
              ...service.additional_data,
            }
          }
          return null
        })
        .filter((service) => service !== null) as RecommendedService[]

      setRecServices(initialRecommendedServices)
    }
  }, [offer.services, services, isSuccess, getParentService])

  useEffect(() => {
    const newTotal = calculateSubTotalPrice(recServices, offer.labor_rate ?? 0)
    const formattedTotal = newTotal.toFixed(2)
    setFee(formattedTotal)
  }, [servicePrices, recServices, offer.services])

  useEffect(() => {
    const calculateSubtotalRequestedServices = () => {
      const laborCost = offer.services
        .filter((service) => service.is_approved)
        .reduce((total, service) => total + (service.price ? parseFloat(service.price) : 0), 0)

      const subtotal =
        laborCost + (parseFloat(offer.tax) || 0) + (parseFloat(offer.supply_fee) || 0)
      setSubtotalRequestedServices(subtotal.toFixed(2))
    }

    calculateSubtotalRequestedServices()
  }, [offer.services, offer.labor_rate, offer.tax, offer.supply_fee])

  useEffect(() => {
    function calculateTotalPrice() {
      const fees = parseFloat(shopSupplyFees?.replace(/,/g, '')) || 0
      const taxes = parseFloat(tax?.replace(/,/g, '')) || 0
      let requestedServicesTotal = offer.services
        .filter((service) => service.is_approved)
        .reduce((total, service) => {
          return total + sanitizeNumber(service.price)
        }, 0)

      const recommendedServicesTotal = calculateSubTotalPrice(recServices, offer.labor_rate ?? 0)

      requestedServicesTotal = Math.ceil(requestedServicesTotal * 100) / 100

      return (recommendedServicesTotal + fees + taxes + requestedServicesTotal).toFixed(2)
    }

    const total = calculateTotalPrice()
    setTotalFee(total)
  }, [recServices, servicePrices, shopSupplyFees, fee, tax, offer.services])

  useEffect(() => {
    if (offer?.files) {
      const feedbackFile = offer.files.find((file) => file.type === 'DIAGNOSTIC_FEEDBACK')
      setDiagnosticFeedbackFile(feedbackFile || null)
      setShowAdditionalServices(true)
      setIsLoading(false)
    }
    setIsLoading(false)
  }, [offer])

  const handleFileSelect = (selectedFile: File) => {
    if (selectedFile.size > MAX_FILE_SIZE) {
      setAlertOpen(true)
      setAlertText('File is too large. Maximum size is 10MB.')
      return
    }
    setFile(selectedFile)
    setUploadStatus('IDLE')
  }

  const handleSubmit = async () => {
    const filteredServices = recServices.filter((service) => service.serviceType !== 'DIAGNOSIS')

    setAlertOpen(false)

    const formattedServices = filteredServices.map((service) => {
      const additionalData = service.additional_data
        ? Object.keys(service.additional_data).reduce((acc, key) => {
            // @ts-ignore
            if (service.additional_data[key] && service.additional_data[key] !== '') {
              // @ts-ignore
              acc[key] = service.additional_data[key]
            }
            const numberFieldsSet = new Set([
              'COLD_CRANKING_AMPS',
              'FACTORY_COLD_CRANKING_AMPS',
              'WARRANTY',
            ])
            if (numberFieldsSet.has(key)) {
              // @ts-ignore
              acc[key] = Number(service.additional_data[key])
            }
            return acc
          }, {})
        : null

      const price =
        service.parts.reduce((total, part) => {
          return total + part.quantity * part.price_per_unit
        }, 0) + service.labor_price

      return {
        service_id: service.type || service.id,
        price: price,
        labor_hours: Number(service.labor_hours),
        ...(diagnosticResult && { diagnostic_result: diagnosticResult }),
        parts: service.parts?.map((part) => ({
          name: part.name,
          quantity: Number(part.quantity),
          price_per_unit: Number(part.price_per_unit),
          number: Number(part.number),
        })),
        additional_data: additionalData,
      }
    })

    const payload = {
      supply_fee: parseFloat(shopSupplyFees?.replace(/[^0-9.-]+/g, '')) || 0,
      tax: parseFloat(tax?.replace(/[^0-9.-]+/g, '')) || 0,
      services: formattedServices,
    }

    try {
      setIsLoading(true)

      await Promise.all(
        recServices.map((service, index) => {
          if (service.imageFiles && service.imageFiles.length > 0) {
            return uploadServiceImages({
              offer_id: offer.id,
              service_id: service.type ?? service.id,
              images: service.imageFiles,
            })
          }
          return Promise.resolve()
        })
      )
      dispatch<any>(submitTechnicalApproval({ offerId: offer.id, body: payload }))
        .then(unwrapResult)
        .then((data: any) => {
          setIsLoading(false)
          onSubmit()
          navigate(0)
        })
    } catch (error) {
      console.error('Error uploading file:', error)
      setIsLoading(false)
    }
  }

  const handleUpload = async () => {
    await handleUploadInspection()
    await handleSubmit()
  }

  const handleUploadInspection = async () => {
    if (!file || !offer.id) {
      console.error('No file or offerId provided.')
      setUploadStatus('ERROR')
      return
    }
    setUploadStatus('UPLOADING')
    const formData = new FormData()
    formData.append('file', file)

    try {
      dispatch<any>(uploadPdf({ offerId: offer.id, fileType: 'INSPECTION', formData }))
        .then(unwrapResult)
        .then((data: any) => {
          setUploadStatus('SUCCESS')
          handleSubmit()
        })
    } catch (error: any) {
      console.error('Error uploading file:', error)
      setUploadStatus('ERROR')
      setAlertText(error ?? 'ERROR on file upload')
    }
  }

  const [uploadServiceImages] = useUploadRecServiceImagesMutation()

  const uploadFeedback = () => {
    switch (uploadStatus) {
      case 'UPLOADING':
        return <Typography color="primary">Uploading...</Typography>
      case 'SUCCESS':
        return <Typography color="green">Upload successful!</Typography>
      case 'ERROR':
        return <Typography color="error">Upload failed. Try again.</Typography>
      default:
        return null
    }
  }

  const renderDroppableArea = () => (
    <div
      onDragOver={(event) => event.preventDefault()}
      onDrop={(event) => {
        event.preventDefault()
        if (event.dataTransfer.files[0]) {
          handleFileSelect(event.dataTransfer.files[0])
        }
      }}
      style={{
        border: '2px dashed #C4C4C4',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        marginBottom: '16px',
        transition: 'background-color 0.3s',
        backgroundColor: '#FAFAFA',
      }}
    >
      <CloudUploadIcon style={{ fontSize: '50px', color: '#C4C4C4' }} />
      <Typography>
        <Button
          variant="text"
          component="span"
          style={{ textTransform: 'none', padding: 0 }}
          color="warning"
          onClick={handleUploadClick}
        >
          Click to upload
        </Button>{' '}
        or drag and drop
      </Typography>
      <Typography variant="caption">PDF, PNG, JPG, or JPEG (max. 25 MB)</Typography>
      <input
        style={{ display: 'none' }}
        ref={fileInputRef}
        id="file-input"
        type="file"
        accept="application/pdf, image/png, image/jpeg, image/jpg"
        onChange={(e) => {
          if (e.target.files?.[0]) {
            handleFileSelect(e.target.files[0])
          }
        }}
      />
      {file && (
        <>
          <Typography variant="body2">{`Selected file: ${file.name}`}</Typography>
          {uploadFeedback()}
        </>
      )}
    </div>
  )

  const handleServiceChange = (
    index: number,
    field: keyof RecommendedService | string,
    value: string | string[] | number
  ) => {
    // @ts-ignore
    setRecServices((currentServices) =>
      currentServices.map((service, idx) => {
        if (idx === index) {
          if (field === 'id') {
            const selectedService = services.find((s) => s.id === value)
            return {
              ...service,
              [field]: value,
              name: selectedService ? selectedService.name : '',
              type: '',
              types: selectedService ? selectedService.children : [],
            }
          } else {
            return { ...service, [field]: value }
          }
        }
        return service
      })
    )
  }

  const handleAdditionalDataChange = useCallback(
    (index: number, field: string, value: string | string[] | number) => {
      //   changes values in service.additional_data
      //   @ts-ignore
      setRecServices((currentServices) =>
        currentServices.map((service, idx) => {
          if (idx === index) {
            return {
              ...service,
              additional_data: {
                ...service.additional_data,
                [field]: value,
              },
            }
          }
          return service
        })
      )
    },
    []
  )

  const handleAddService = () => {
    const newService: RecommendedService = {
      id: `service_${recServices.length}`,
      name: '',
      type: '',
      types: [],
      price: 0,
      serviceType: '',
      labor_hours: 0,
      parts: [],
      imageFiles: [],
      additional_data: null,
      labor_price: 0,
    }
    setRecServices((prevServices) => [...prevServices, newService])
  }

  const handleRemoveService = (serviceId: string, serviceType: string) => {
    setRecServices((prevServices) =>
      prevServices.filter(
        (service) => `${service.id}_${service.type}` !== `${serviceId}_${serviceType}`
      )
    )
    setServicePrices((prevPrices) => {
      const updatedPrices = { ...prevPrices }
      delete updatedPrices[`${serviceId}_${serviceType}`]
      return updatedPrices
    })
  }

  const handleAddServicePart = (serviceIndex: number) => {
    setRecServices((prevServices) =>
      prevServices.map((service, index) =>
        index === serviceIndex
          ? {
              ...service,
              parts: service.parts
                ? [
                    ...service.parts,
                    {
                      name: '',
                      quantity: 1,
                      price_per_unit: 0,
                      number: 0,
                    },
                  ]
                : [],
            }
          : service
      )
    )
  }

  const handleImagesUpload = useCallback((files: File[], index: number) => {
    setRecServices((currentServices) =>
      currentServices.map((service, idx) => {
        if (idx === index) {
          return {
            ...service,
            imageFiles: files,
          }
        }
        return service
      })
    )
  }, [])

  const handleRemoveServicePart = (serviceIndex: number, partIndex: number) => {
    setRecServices((prevServices) => {
      const updatedServices = [...prevServices]
      updatedServices[serviceIndex].parts.splice(partIndex, 1)
      return updatedServices
    })
  }

  const renderServiceBlocks = useCallback(
    () => (
      <>
        {recServices.map((service, index) => {
          const isFullGeneralDiagnostic = service.name === 'Full general diagnostic'

          return (
            <RecServiceBlock
              service={service}
              recServices={recServices}
              key={`${service.id}_${service.type}_${index}`}
              index={index}
              services={services}
              servicePrices={servicePrices}
              offer={offer}
              handleServiceChange={handleServiceChange}
              handleServicePartChange={handleServicePartChange}
              handleServicePriceChange={handleServicePriceChange}
              handleRemoveService={handleRemoveService}
              handleAddServicePart={handleAddServicePart}
              handleImagesUpload={handleImagesUpload}
              handleRemoveServicePart={handleRemoveServicePart}
              handleAdditionalDataChange={handleAdditionalDataChange}
              disableServiceChange={isFullGeneralDiagnostic}
            />
          )
        })}
      </>
    ),
    [recServices, services, servicePrices, offer, handleServiceChange]
  )

  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    const isValid = recServices.every((service) =>
      validateRecommendedServiceFields(services, service, offer.labor_rate ?? 0)
    )
    setIsFormValid(isValid)
  }, [recServices, services, offer.labor_rate])

  const renderSummary = () => {
    if (recServices.length === 0) {
      return null
    }

    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2} mb={2}>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              sx={{
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#393A3D',
                marginRight: '8px',
              }}
            >
              Subtotal (Recommended services):
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#DB5D08',
              }}
            >
              {fee && formatPrice(fee)}
            </Typography>
          </Box>
          <Divider sx={{ marginY: '16px !important' }} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#393A3D',
              }}
            >
              Shop supply fees:
            </Typography>
          </Stack>
          <TextField
            value={shopSupplyFees}
            onChange={handleFeeChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              style: { background: '#fff' },
            }}
            inputProps={{
              maxLength: 9,
              style: { background: '#fff', height: '17px' },
            }}
            style={{ width: '30%' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Stack spacing={1}>
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: APP_FONT,
                fontWeight: '600',
                color: '#393A3D',
                pr: 2,
              }}
            >
              Tax:
            </Typography>
          </Stack>
          <TextField
            value={tax}
            onChange={handleTaxChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              style: { background: '#fff' },
            }}
            inputProps={{
              maxLength: 9,
              style: { background: '#fff', height: '17px' },
            }}
            style={{ width: '30%' }}
          />
        </Box>

        <Box display="flex" gap={4}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#393A3D',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              mb: 2,
            }}
          >
            Total:
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: '600',
              color: '#DB5D08',
            }}
          >
            {totalFee && formatPrice(totalFee)}
          </Typography>
        </Box>
      </>
    )
  }

  const renderDiagnosticFeedbackFile = () => {
    if (!diagnosticFeedbackFile) return null

    return (
      <Box
        sx={{
          mt: 2,
          mb: 4,
          p: 2,
          border: 1,
          borderColor: '#FFB38C',
          borderRadius: 'borderRadius',
          bgcolor: 'background.paper',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#FF6600' }}>
          Carma ASE-Certified Master Technician Feedback
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: 2 }}>
          Download below to view feedback, make necessary adjustments to theapproval, and resubmit:
        </Typography>
        <Box
          sx={{
            width: '80%',
            padding: 2,
            boxShadow: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PdfDownloadIcon />
            <Typography variant="body2" sx={{ ml: 2 }}>
              {diagnosticFeedbackFile.name} ({diagnosticFeedbackFile.size})
            </Typography>
          </Box>
          <Button
            color="warning"
            href={diagnosticFeedbackFile.url}
            download
            startIcon={<DownloadIcon />}
            target="_blank"
          />
        </Box>
      </Box>
    )
  }

  const handleFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setShopSupplyFees(formattedNumber)
  }

  const handleTaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value.replace(/[^0-9]/g, '')
    const number = parseInt(input, 10) / 100

    const formattedNumber = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(number)
      .slice(1)

    setTax(formattedNumber)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      {isLoading ? (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 2, height: '120vh' }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress
            size={60}
            sx={{
              color: '#FF6600',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-30px',
              marginLeft: '-30px',
            }}
          />
        </Backdrop>
      ) : (
        <>
          <Box>
            <ModalTableHead
              onHide={onClose}
              service_request={{
                slug: offer.consumer_request.slug,
                vehicle: offer.vehicle,
                services: offer.services,
                drop_off_time: offer.drop_off_by,
                is_diagnostic: offer.consumer_request.type === 'DIAGNOSTIC',
              }}
            />
          </Box>
          {alertOpen && <Alert severity="error">{alertText}</Alert>}
          <DialogContent>
            <Grid container spacing={2} sx={{ height: '100%' }}>
              <Grid item xs={12} md={5} sx={{ borderRight: '1px solid #EAECF0', pr: 2 }}>
                {renderDiagnosticFeedbackFile()}
                {offer?.consumer_request.files.length > 0 ? (
                  <>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Concern media
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                      Photos and videos uploaded by customer to explain concern
                    </Typography>
                    <Box display="flex" justifyContent="flex-start" alignItems="center" mb={2}>
                      <FilePreview files={offer?.consumer_request.files || []} />
                    </Box>
                  </>
                ) : (
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                    No media was uploaded by the customer
                  </Typography>
                )}
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Requested services
                </Typography>
                {offer.services
                  .filter((service) => service.is_approved)
                  .map((service, index) => (
                    <Box key={`${service.id}_${service.type}_${index}`}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: 1,
                          mt: 1,
                        }}
                      >
                        <Stack spacing={1}>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontFamily: APP_FONT,
                              fontWeight: '600',
                              color: '#393A3D',
                              mt: 1,
                            }}
                          >
                            {service.service.parent.parent_id
                              ? service.service.parent.name
                              : service.service.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {service.service.parent.parent_id && service.service.name}
                          </Typography>
                          {service.service.required_data?.includes('PMI') && (
                            <a
                              href="https://joincarma-images.s3.amazonaws.com/PMI+final.pdf"
                              download
                              target="_blank"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                                color: '#FF6600',
                              }}
                              rel="noreferrer"
                            >
                              <Typography
                                fontFamily={APP_FONT}
                                fontSize={16}
                                mr={1}
                                style={{
                                  fontSize: '16px',
                                  fontFamily: APP_FONT,
                                  fontWeight: '600',
                                  color: '#FF6600',
                                }}
                              >
                                PMI Download Link
                              </Typography>
                              <DownloadIcon color="#FF6600" />
                            </a>
                          )}
                        </Stack>
                        <Typography
                          sx={{
                            fontSize: '20px',
                            fontFamily: APP_FONT,
                            fontWeight: '600',
                            color: '#393A3D',
                          }}
                        >
                          {formatPrice(
                            new Decimal(sanitizeNumber(service.labor_hours))
                              .mul(offer.labor_rate ?? 0)
                              .toDecimalPlaces(2)
                          )}
                        </Typography>
                      </Box>
                      {index < offer.services.length - 1 && <Divider />}
                    </Box>
                  ))}
                <Divider sx={{ my: 2 }} />
                {offer?.consumer_request?.type === 'DIAGNOSTIC' && (
                  <>
                    <Box key={`full_general_diagnostic`}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: 1,
                          mt: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontFamily: APP_FONT,
                            fontWeight: '600',
                            color: '#393A3D',
                            mt: 1,
                          }}
                        >
                          Full general diagnostic
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '20px',
                            fontFamily: APP_FONT,
                            fontWeight: '600',
                            color: '#393A3D',
                          }}
                        >
                          $0.00
                        </Typography>
                      </Box>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                  </>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                      mr: 2,
                    }}
                  >
                    Shop supply fees:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                    }}
                  >
                    {formatPrice(offer.supply_fee ?? '0.00')}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                      mr: 2,
                    }}
                  >
                    Tax
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                    }}
                  >
                    {formatPrice(offer.tax ?? '0.00')}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#393A3D',
                      mr: 2,
                    }}
                  >
                    {offer.services.length > 1 ? 'Subtotal:' : 'Total:'}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: '600',
                      color: '#DB5D08',
                    }}
                  >
                    {subtotalRequestedServices
                      ? formatPrice(subtotalRequestedServices)
                      : offer.total_fee}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3, color: '#FF6600' }}>
                  Diagnostic results:
                </Typography>
                <Grid item xs={12} sx={{ flexGrow: 1, marginBottom: '10px' }}>
                  <Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>
                    Please report the results of the diagnostic in detail. This is required and is
                    printed on the diagnostic report.
                  </Typography>
                  <TextField
                    style={{
                      width: '100%',
                    }}
                    placeholder="Type the results (in detail) of the diagnostic here..."
                    multiline
                    rows={4}
                    maxRows={4}
                    onChange={(e) => setDiagnosticResult(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sx={{ flexGrow: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: APP_FONT,
                      fontWeight: 'bold',
                      fontSize: '16px',
                      color: '#393A3D',
                    }}
                  >
                    Vehicle inspection (optional)
                  </Typography>
                  <Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>
                    If you performed a Digital Vehicle Inspection (DVI) or standard inspection
                    checklist, please upload it here:
                  </Typography>
                  {renderDroppableArea()}
                  {!showAdditionalServices && (
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: '700',
                          textAlign: 'center',
                          mb: 3,
                          mt: 3,
                          color: '#393A3D',
                        }}
                      >
                        Does this vehicle need any other services? If so, use the button below to
                        add them.
                      </Typography>

                      <Stack sx={{ alignItems: 'center' }}>
                        <Button
                          variant="outlined"
                          color="warning"
                          onClick={() => setShowAdditionalServices(true)}
                          sx={{
                            width: 'fit-content',
                            borderRadius: '20px',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            paddingLeft: '32px',
                            paddingRight: '32px',
                          }}
                        >
                          <PlusIcon style={{ color: '#ed6c02  !important', marginRight: '8px' }} />
                          <span>Recommend additional services</span>
                        </Button>
                      </Stack>
                    </Stack>
                  )}

                  {showAdditionalServices && (
                    <Box sx={{ flexGrow: 1 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: 'bold', mb: 3, mt: 3, color: '#FF6600' }}
                        >
                          Additional recommended services
                        </Typography>

                        <Box>
                          <Button
                            variant="contained"
                            onClick={() => setShowAdditionalServices(false)}
                            sx={{
                              width: 'fit-content',
                              borderRadius: '20px',
                              textTransform: 'none',
                              fontWeight: 'bold',
                              paddingLeft: '32px',
                              paddingRight: '32px',
                              backgroundColor: '#fff',
                              mt: 3,
                              color: '#000',
                              '&:hover': {
                                backgroundColor: '#e0e0e0',
                              },
                              '&:active': {
                                backgroundColor: '#grey',
                                color: '#fff',
                              },
                            }}
                          >
                            <Close />{' '}
                            <Typography sx={{ ml: 2 }}> Remove additional services</Typography>
                          </Button>
                        </Box>
                      </Box>

                      <Typography sx={{ color: 'text.secondary', marginBottom: 2 }}>
                        List all the services which customer's vehicle needs
                      </Typography>
                      {renderServiceBlocks()}
                      <Button
                        onClick={handleAddService}
                        variant="outlined"
                        color="warning"
                        sx={{ mt: 1, textTransform: 'none' }}
                      >
                        <AddIcon />
                        <span style={{ margin: '4px', paddingLeft: '10px', color: '#FF6600' }}>
                          Add service
                        </span>
                      </Button>
                      {renderSummary()}
                    </Box>
                  )}
                </Grid>
                <Box display="flex" justifyContent="center" mt={2}>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={handleUpload}
                    disabled={!isFormValid}
                    sx={{
                      width: 'fit-content',
                      borderRadius: '20px',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      paddingLeft: '32px',
                      paddingRight: '32px',
                    }}
                  >
                    Submit for technician approval
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default DiagnosticUploadModal
