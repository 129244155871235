import React, { useMemo, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import DeleteIcon from '../../../../../assets/images/delete.svg'
import Autocomplete from '@mui/material/Autocomplete'
import { RecServicePhotoUpload } from './RecServicePhotoUpload'
import { APP_FONT } from '../../../../../constants/app_font'
import { Service } from './inspection_upload'
import { TirePositions } from './selects/TirePositions'
import { TireInfo } from './selects/TireInfo'
import { CrankingAmps } from './selects/CrankingAmps'
import { Axles } from './selects/Axles'
import { ReactComponent as Plus } from '../../../../../assets/images/plus.svg'
import { formatPrice, sanitizeNumber } from '../../../../../components/helper/helper'
import { RecommendedService, RecommendedServicePart } from '../service.model'
import Decimal from 'decimal.js'

interface RecServiceBlockProps {
  service: RecommendedService
  index: number
  recServices: RecommendedService[]
  services: Service[]
  servicePrices: { [serviceId: string]: string }
  offer: {
    // incomplete
    id: string
    labor_rate: number | null
  }
  handleServiceChange: (
    index: number,
    field: keyof RecommendedService | string,
    value: string | string[] | number
  ) => void
  handleServicePartChange: (
    index: number,
    partIndex: number,
    field: keyof RecommendedServicePart,
    value: string
  ) => void
  handleServicePriceChange: (
    serviceId: string,
    serviceType: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void
  handleRemoveService: (serviceId: string, serviceType: string) => void
  handleAddServicePart: (serviceIndex: number) => void
  handleImagesUpload: (files: File[], index: number) => void
  handleRemoveServicePart: (serviceIndex: number, partIndex: number) => void
  handleAdditionalDataChange: (
    index: number,
    field: string,
    value: string | string[] | number
  ) => void
  disableServiceChange?: boolean
}

export const RecServiceBlock: React.FC<RecServiceBlockProps> = ({
  service,
  recServices,
  index,
  services,
  servicePrices,
  offer,
  handleServiceChange,
  handleServicePartChange,
  handleServicePriceChange,
  handleRemoveService,
  handleAddServicePart,
  handleImagesUpload,
  handleRemoveServicePart,
  handleAdditionalDataChange,
  disableServiceChange,
}) => {
  if (offer.labor_rate === null) {
    console.warn('Labor rate is not set')
  }
  const [options, setOptions] = useState<Service[]>(services)
  const [loading, setLoading] = useState<boolean>(false)

  const fetchServices = async (query: string) => {
    if (query) {
      setLoading(true)
      setOptions([])
      try {
        const response = await fetch(
          `${process.env.REACT_APP_NEST_JS_API}service?level=1&search=${query}`
        )
        const data = await response.json()

        const filteredOptions = data.items.filter(
          (item: any) =>
            item.name.toLowerCase().includes(query.toLowerCase()) && item.parent_id !== null
        )

        setOptions(filteredOptions)
      } catch (error) {
        console.error('Failed to fetch services:', error)
      } finally {
        setLoading(false)
      }
    } else {
      setOptions(services)
    }
  }

  const handleInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
    fetchServices(newInputValue)
  }

  const selectedService = useMemo(() => {
    return services.find((s) => s.id === service.id)
  }, [services, service.id, service.type])

  const selectedServiceType = useMemo(() => {
    return service.types.find((s) => s.id === service.type)
  }, [services, service.id, service.type])

  const recommendedRequiredData =
    selectedServiceType?.recommend_required_data || selectedService?.recommend_required_data || []

  const { IS_TIRE_POSITIONS, IS_TIRE_INFO, IS_CRANKING_AMPS, IS_FRONT_AXLE, IS_REAR_AXLE } =
    useMemo(() => {
      return {
        IS_TIRE_POSITIONS: recommendedRequiredData.includes('TIRE_POSITIONS'),
        IS_TIRE_INFO: recommendedRequiredData.includes('TIRE_BRAND'),
        IS_CRANKING_AMPS: recommendedRequiredData.includes('COLD_CRANKING_AMPS'),
        IS_FRONT_AXLE:
          recommendedRequiredData.includes('LF_PAD') && recommendedRequiredData.includes('RF_PAD'),
        IS_REAR_AXLE:
          recommendedRequiredData.includes('LR_PAD') && recommendedRequiredData.includes('RR_PAD'),
      }
    }, [recommendedRequiredData])

  const totalPrice = useMemo(() => {
    const partsTotal = service.parts.reduce((acc, part) => {
      console.log(part)
      const partTotal = new Decimal(part.price_per_unit).mul(part.quantity)
      return acc.plus(partTotal)
    }, new Decimal(0))

    const laborTotal = new Decimal(sanitizeNumber(service.labor_hours)).mul(offer.labor_rate || 0)

    const total = partsTotal.plus(laborTotal)

    return total.toDecimalPlaces(2).toNumber()
  }, [service, offer.labor_rate])

  console.log(offer)
  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        alignItems="center"
        mb={2}
        style={{
          width: '100%',
          backgroundColor: '#F9FAFB',
          padding: '12px',
          borderRadius: '8px',
          marginTop: '12px',
          marginBottom: '12px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          border: '1px solid #E5E7EB',
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <Grid container spacing={2} alignItems="center" justifyContent="flex-end" sx={{ mb: 2 }}>
          {service.serviceType !== 'DIAGNOSIS' && recServices.length > 0 && (
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <IconButton
                onClick={() => handleRemoveService(service.id, service.type)}
                size="small"
                sx={{
                  padding: 0,
                  gap: '8px',
                }}
              >
                <img src={DeleteIcon} alt="Delete" />
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontFamily: APP_FONT,
                    fontWeight: 'bold',
                    color: '#727373',
                    textAlign: 'center',
                  }}
                >
                  Remove service
                </Typography>
              </IconButton>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{
            display: 'flex',
          }}
        >
          {service.serviceType === 'DIAGNOSIS' ? (
            <Grid item xs={6}>
              <TextField
                disabled={disableServiceChange}
                size="small"
                fullWidth
                value={selectedService?.name || undefined}
                InputProps={{
                  readOnly: true,
                  style: { background: '#fff' },
                }}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={6}>
                <Autocomplete
                  options={options}
                  value={selectedService}
                  onChange={(event, newValue: any) => {
                    handleServiceChange(index, 'id', newValue?.id || '')
                  }}
                  onInputChange={handleInputChange}
                  getOptionLabel={(option: any) => option.name}
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Service"
                      placeholder="Select service"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      sx={{ backgroundColor: '#fff' }}
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    )
                  }}
                  blurOnSelect={true}
                />
              </Grid>
            </>
          )}

          {service.serviceType !== 'DIAGNOSIS' && (
            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                label="Type"
                placeholder="Select service type"
                value={service.type}
                onChange={(e) => handleServiceChange(index, 'type', e.target.value)}
                disabled={!service.name || !service.types.length}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: '50vh',
                      },
                    },
                  },
                }}
                sx={{ backgroundColor: '#fff' }}
              >
                {service.types.map((type) => (
                  <MenuItem key={`${type.id}_${type.name}`} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </Grid>
        <Box
          sx={{
            width: '100%',
          }}
        >
          {IS_TIRE_POSITIONS ? (
            // @ts-ignore
            <TirePositions
              // @ts-ignore
              tirePositions={service.additional_data?.TIRE_POSITIONS}
              values={{
                // @ts-ignore
                LF_MEASUREMENT: service.additional_data?.LF_MEASUREMENT,
                // @ts-ignore
                LR_MEASUREMENT: service.additional_data?.LR_MEASUREMENT,
                // @ts-ignore
                RF_MEASUREMENT: service.additional_data?.RF_MEASUREMENT,
                // @ts-ignore
                RR_MEASUREMENT: service.additional_data?.RR_MEASUREMENT,
                // @ts-ignore
                LR_INSIDE_MEASUREMENT: service.additional_data?.LR_INSIDE_MEASUREMENT,
                // @ts-ignore
                RR_INSIDE_MEASUREMENT: service.additional_data?.RR_INSIDE_MEASUREMENT,
              }}
              onChange={(field, value) => {
                handleAdditionalDataChange(index, field, value)
              }}
              onTirePositionsChange={(value) => {
                handleAdditionalDataChange(index, 'TIRE_POSITIONS', value)
              }}
            />
          ) : null}
          {IS_TIRE_INFO ? (
            <TireInfo
              values={{
                TIRE_BRAND:
                  typeof service.additional_data?.TIRE_BRAND === 'object'
                    ? // @ts-ignore
                      service.additional_data?.TIRE_BRAND?.name || ''
                    : service.additional_data?.TIRE_BRAND || '',
                // @ts-ignore
                TIRE_MODEL: service.additional_data?.TIRE_MODEL,
                // @ts-ignore
                WARRANTY: service.additional_data?.WARRANTY || 0,
              }}
              onChange={(field, value) => {
                handleAdditionalDataChange(index, field, value)
              }}
            />
          ) : null}
          {IS_CRANKING_AMPS ? (
            <CrankingAmps
              values={{
                // @ts-ignore
                COLD_CRANKING_AMPS: service.additional_data?.COLD_CRANKING_AMPS,
                // @ts-ignore
                FACTORY_COLD_CRANKING_AMPS: service.additional_data?.FACTORY_COLD_CRANKING_AMPS,
              }}
              onChange={(field, value) => {
                handleAdditionalDataChange(index, field, value)
              }}
            />
          ) : null}
          <Axles
            values={{
              // @ts-ignore
              LF_PAD: service.additional_data?.LF_PAD,
              // @ts-ignore
              LR_PAD: service.additional_data?.LR_PAD,
              // @ts-ignore
              RF_PAD: service.additional_data?.RF_PAD,
              // @ts-ignore
              RR_PAD: service.additional_data?.RR_PAD,
            }}
            onChange={(field, value) => {
              handleAdditionalDataChange(index, field, value)
            }}
            isFront={IS_FRONT_AXLE}
            isRear={IS_REAR_AXLE}
          />
          <InputLabel
            style={{
              fontFamily: APP_FONT,
              marginTop: '20px',
              marginBottom: '20px',
              fontWeight: 'bold',
              color: '#393A3D',
              fontSize: '14px',
            }}
          >
            Labor:
          </InputLabel>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              marginBottom: '20px',
            }}
          >
            <TextField
              label="Number of labor hours"
              type="text"
              value={service?.labor_hours?.toString() ?? ''}
              onChange={(e) => {
                const input = e.target.value.replace(/[^0-9]/g, '')
                const number = input === '' ? 0 : parseInt(input, 10) / 10

                const formattedNumber = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                })
                  .format(number)
                  .slice(1)

                handleServiceChange(index, 'labor_hours', formattedNumber)
                handleServiceChange(
                  index,
                  'labor_price',
                  parseFloat(formattedNumber) * (offer.labor_rate ?? 0)
                )
              }}
              InputProps={{
                style: {
                  background: '#FFFFFF',
                  borderRadius: '4px',
                  height: '40px',
                },
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
              }}
            />

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <InputLabel
                sx={{
                  fontFamily: APP_FONT,
                  fontWeight: 500,
                  marginRight: '8px',
                  color: '#475467',
                  fontSize: '14px',
                }}
              >
                Labor rate: {formatPrice(offer.labor_rate ?? 0)}/hr
              </InputLabel>
              <TextField
                label="Price"
                type="text"
                value={formatPrice(
                  new Decimal(sanitizeNumber(service.labor_hours))
                    .mul(offer.labor_rate ?? 0)
                    .toDecimalPlaces(2)
                )}
                InputProps={{
                  sx: {
                    height: '40px',
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#393A3D',
                    },
                  },
                }}
                disabled
              />
            </Box>
          </Box>

          {service.parts?.length > 0 && (
            <Box
              sx={{
                marginTop: '16px',
              }}
            >
              <InputLabel
                style={{
                  fontFamily: APP_FONT,
                  marginTop: '20px',
                  marginBottom: '20px',
                  fontWeight: 'bold',
                  color: '#393A3D',
                  fontSize: '14px',
                }}
              >
                Parts:
              </InputLabel>
              {service.parts.map((part, partIndex) => (
                <Box key={partIndex}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      gap: '16px',
                    }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      label="Part name"
                      placeholder="Enter part name"
                      value={part.name}
                      onChange={(e) =>
                        handleServicePartChange(index, partIndex, 'name', e.target.value)
                      }
                      InputProps={{
                        style: { background: '#fff' },
                      }}
                    />
                    <TextField
                      size="small"
                      label="Quantity"
                      value={part.quantity}
                      onChange={(e) =>
                        handleServicePartChange(index, partIndex, 'quantity', e.target.value)
                      }
                      InputProps={{
                        style: { background: '#fff' },
                      }}
                    />
                    <TextField
                      size="small"
                      label="Price per unit"
                      value={formatPrice(part.price_per_unit)}
                      onChange={(e) =>
                        handleServicePartChange(index, partIndex, 'price_per_unit', e.target.value)
                      }
                      InputProps={{
                        style: { background: '#fff' },
                      }}
                    />
                    <TextField
                      disabled={true}
                      size="small"
                      label="Total"
                      value={formatPrice(part.price_per_unit * part.quantity)}
                      aria-readonly
                      InputProps={{
                        sx: {
                          background: '#fff',
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#393A3D',
                          },
                        },
                      }}
                    />
                    <IconButton
                      onClick={() => handleRemoveServicePart(index, partIndex)}
                      size="large"
                    >
                      <img src={DeleteIcon} alt="Delete" />
                    </IconButton>
                  </Box>
                  <TextField
                    size="small"
                    label="Part number"
                    placeholder="Enter part number"
                    value={part.number}
                    onChange={(e) =>
                      handleServicePartChange(index, partIndex, 'number', e.target.value)
                    }
                    sx={{
                      marginTop: '20px',
                      marginBottom: '20px',
                    }}
                    InputProps={{
                      style: { background: '#fff' },
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}
          <Button
            startIcon={<Plus />}
            variant="text"
            color="primary"
            onClick={() => handleAddServicePart(index)}
            sx={{
              fontSize: '14px',
              textTransform: 'none',
              color: '#DB5D08',
              fontWeight: 'bold',
              padding: 0,
              paddingLeft: 1,
              margin: 0,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <span style={{ marginLeft: '10px', margin: 2, padding: 2, alignItems: 'center' }}>
              Add part
            </span>
          </Button>
          {selectedService && (selectedService?.is_photo_upload_required || totalPrice > 500) ? (
            <RecServicePhotoUpload
              offerId={offer.id}
              serviceId={service.type || service.id}
              onUpload={(files) => handleImagesUpload(files, index)}
              serviceName={selectedService.name || ''}
            />
          ) : null}
        </Box>
        <Box
          display="flex"
          mt="12px"
          style={{
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Typography
            variant="body1"
            style={{
              fontWeight: '600',
              fontFamily: APP_FONT,
              fontSize: 16,
              marginRight: '16px',
              color: '#111827',
            }}
          >
            Subtotal:
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontWeight: '600',
              fontFamily: APP_FONT,
              fontSize: 16,
              color: '#111827',
            }}
          >
            {formatPrice(totalPrice)}
          </Typography>
        </Box>
      </Grid>
    </React.Fragment>
  )
}
